// SEO metadata for FlutterFX
const seoMetadata = {
    title: "FlutterFX | Animated Flutter Plugins & Templates",
    description: "Discover daily Flutter animations, free templates, and UI kits. Learn custom animations and boost your app's UX with FlutterFX's curated collection of animated plugins.",
    keywords: [
        "flutter animation",
        "flutter templates free",
        "flutter template",
        "flutter ui kit",
        "animated text flutter",
        "custom animation service"
    ],
    openGraph: {
        title: "FlutterFX - Elevate Your Flutter App's UX",
        description: "Explore daily Flutter animations, free templates, and UI kits. Enhance your app's user experience with our curated animated plugins.",
        image: "https://www.flutterfx.com/og-image.jpg", // Replace with your actual OG image
        url: "https://www.flutterfx.com",
        type: "website"
    },
    twitter: {
        card: "summary_large_image",
        site: "@flutterfx",
        title: "FlutterFX | Daily Flutter Animations & Templates",
        description: "Discover Flutter animations, free templates, and UI kits. Boost your app's UX with our curated animated plugins.",
        image: "https://www.flutterfx.com/twitter-image.jpg" // Replace with your actual Twitter card image
    }
};

export default seoMetadata;